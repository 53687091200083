/*noinspection CssInvalidAtRule*/
@import-normalize;

@font-face {
  font-family: 'lvmh_regular';
  src: url('assets/fonts/lvmh-regular.ttf');
  src: url('assets/fonts/lvmh-regular.ttf?#iefix') format("embedded-opentype"),url('assets/fonts/lvmhweb-regular.woff') format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'romain_reguRgText';
  src: url('assets/fonts/romain_bp_text_regular.eot');
  src: url('assets/fonts/romain_bp_text_regular.eot?#iefix') format("embedded-opentype"),url('assets/fonts/romain_bp_text_regular.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'romain_reguRgText_italic';
  src: url('assets/fonts/romain_bp_text_regular_italic.eot');
  src: url('assets/fonts/romain_bp_text_regular_italic.eot?#iefix') format("embedded-opentype"),url('assets/fonts/romain_bp_text_regular_italic.woff') format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'romain_reguRg';
  src: url('assets/fonts/romain_bp_headline_regular.eot');
  src: url('assets/fonts/romain_bp_headline_regular.eot?#iefix') format("embedded-opentype"),url('assets/fonts/romain_bp_headline_regular.woff') format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'romain_bp_headline_boldbold';
  src: url('assets/fonts/romain_bp_headline_bold.eot');
  src: url('assets/fonts/romain_bp_headline_bold.eot?#iefix') format("embedded-opentype"),url('assets/fonts/romain_bp_headline_bold.woff') format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'LVRegular';
  src: url('assets/fonts/LouisVuitton-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'LVDemi';
  src: url('assets/fonts/LouisVuitton-Demi.otf') format('opentype');
}

@font-face {
  font-family: 'LVBold';
  src: url('assets/fonts/LouisVuitton-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'LVLight';
  src: url('assets/fonts/LouisVuitton-Light.otf') format('opentype');
}

@font-face {
  font-family: 'LVOblique';
  src: url('assets/fonts/LouisVuitton-Oblique.otf') format('opentype');
}

@font-face {
  font-family: 'LVLightOblique';
  src: url('assets/fonts/LouisVuitton-LightOblique.otf') format('opentype');
}

* {
  font-family: 'LVRegular', 'Helvetica', sans-serif;
}

body {
  margin: 0;
  font-family: 'LVRegular', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* == reset style == */
button {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
}

.ReactCollapse--collapse {
  transition: height 250ms;
}
