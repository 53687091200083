/* ############################################## SVG ################################# */

.nodeHasChildren {
  fill: white;
}

.nodeDoesNotHaveChildren {
  fill: white;
}

.nodeRepresentsCurrentUser {
  stroke: Chartreuse;
  stroke-width: 3;
}

text {
  fill: dimgray;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.node {
  cursor: pointer;
}

.node-foreign-object {
  user-select: none;
  overflow: unset;
}

.node-collapse {
  stroke: grey;
}

.node-collapse-right-rect {
}

.node text {
  fill: white;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 16px;
}

.node circle {
  stroke-width: 1px;
  stroke: darkgray;
  fill: lightgray;
}

.node-group .emp-name {
  fill: #962828;
  font-size: 12px;
  font-weight: 600;
}

.node-group .emp-position-name {
  fill: #59525b;
  font-size: 11px;
}

.node-group .emp-area {
  fill: #91a4a5;
  font-size: 10px;
}

.node-group .emp-count,
.node-group .emp-count-icon {
  fill: #91a4a5;
  font-size: 12px;
}

.node-foreign-object:hover {
  background: rgba(200, 200, 200, 0.1);
}

/*
OMR
 */

.group-key-position {
  border: 2px solid red;
}

.company-key-position {
  border: 2px solid #3937fc;
}

.group-key-contributor {
  color: red;
}

.company-key-contributor {
  color: #3937fc;
}

.futur-a {
  outline: 4px dashed #f7c11f;
}

.high-retention-risk {
  color: red;
  font-family: "Georgia", sans-serif;
  font-size: 25px;
}

.critical-expert {
  color: #0929a8;
  font-weight: bold;
}

.career-aspirations {
  color: #ed7d31;
  font-size: 22px;
  font-family: "LVRegular";
}

.title {
  background: #f9f9f9;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.title-main {
  color: #213d5e;
  text-transform: uppercase;
}

.title-sub {
  color: #a96911;
  text-transform: uppercase;
}

.legend {
  background: #f9f9f9;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}

.legend th {
  text-align: left;
  text-transform: uppercase;
  padding: 0px 10px 0px 40px;
}

.legend tr {
  height: 1px;
}

.legend td {
  text-align: left;
  height: inherit;
  padding: 10px 10px 10px 20px;
}


.legend td div {
  height: 100%;
}



.legend .group-key-position {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.legend .company-key-position {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.legend .cell {
  display: inline-flex;
}

.legend .sub-title {
  font-size: 18px;
  text-transform: none;
  margin-top: -10px;
  float: left;
}

.legend .label {
  margin-left: 20px;
}
