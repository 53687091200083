
.full-container {
    background-color: red;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}


/* ########################   DEPARTMENT INFO  ############################*/

.department-information {
    font-family: 'Roboto', sans-serif;
    display: none;
    box-shadow: 0 0 5px #999999;
    position: absolute;
    max-width: 200px;
    top: 60px;
    left: 20px;
    padding: 10px;
    background-color: white;
}

.department-information .dept-name {
    color: #26a69a;
    font-weight: bold;
}

.department-information .dept-description {
    margin-top: 10px;
    color: #959b9a;
    font-size: 13px;
}

.department-information .dept-emp-count {
    margin-top: 10px;
    color: #959b9a;
    font-size: 13px;
}

/* ############################## SEARCHBOX ######################################### */

.user-search-box {
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width: 0;
    background-color: white;
    border: 1px solid #c7dddb;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bcbcc4;
    opacity: 0.5;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bcbcc4;
    opacity: 0.5;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bcbcc4;
    opacity: 0.5;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bcbcc4;
    opacity: 0.5;
}

.user-search-box .input-box {
    width: 100%;
    height: 200px;
    top: 0;
    background-color: #e8efee;
}

.user-search-box .close-button-wrapper i {
    margin: 10px;
    margin-left: 9%;
    font-size: 60px;
    font-weight: 400;
    color: #aa1414;
}

.user-search-box input {
    color: gray !important;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
}

.user-search-box input:focus {
    border-bottom: 1px solid #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
}

.user-search-box .result-header {
    background-color: white;
    font-weight: 700;
    padding: 12px;
    color: gray;
    border-top: 2px solid #d3e8e5;
    border-bottom: 1px solid #d3e8e5;
}

.user-search-box .result-list {
    position: absolute;
    max-height: 100%;
    min-width: 100%;
    overflow: auto;
}

.user-search-box .buffer {
    width: 100%;
    height: 400px;
}

.user-search-box .list-item {
    clear: both;
    background-color: white;
    position: relative;
    background-color: white;
    width: 100%;
    height: 100px;
    border-top: 1px solid #d3e8e5;
}

.user-search-box .list-item a {
    display: inline;
    margin: 0;
}

.user-search-box .list-item .image-wrapper {
    float: left;
    width: 100px;
    height: 100px;
}

.user-search-box .list-item .image {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 5px;
}

.user-search-box .list-item .description {
    padding: 15px;
    padding-left: 0px;
    float: left;
    width: 180px;
}

.user-search-box .list-item .buttons {
    padding: 15px;
    padding-left: 0px;
    float: left;
    width: auto;
}

.user-search-box .list-item .description .name {
    font-size: 15px;
    color: #aa1414;
    font-weight: 900;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
}

.user-search-box .list-item .description .position-name {
    color: #59525b;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 900;
    margin: 0;
    margin-top: 3px;
    padding: 0;
}

.user-search-box .list-item .description .area {
    color: #91a4a5;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    margin-top: 3px;
    padding: 0;
}

.user-search-box .list-item .btn-locate {
    margin-top: 30px;
}

.user-search-box .list-item .btn-search-box {
    font-size: 10px;
}

.user-search-box .close-button-wrapper i:hover {
    color: black;
    cursor: pointer;
}

.user-search-box .input-wrapper {
    width: 80%;
    margin: 0 auto;
}

.user-search-box .input-bottom-placeholder {
    margin-top: -16px;
    color: #bcbcc4;
    letter-spacing: 1px;
}


/* ############################### Tooltip css ########################### */

.profile-image-wrapper {
    background-size: 210px;
    margin: 30px;
    border-radius: 50%;
    width: 210px;
    height: 210px;
}

.customTooltip-wrapper {
    font-family: 'Roboto', sans-serif;
    opacity: 0;
    /* NEW */
    display: none;
    position: absolute;
}

.customTooltip {
    background: white;
    box-shadow: 0 0 5px #999999;
    color: #333;
    position: absolute;
    font-size: 12px;
    left: 130px;
    text-align: center;
    top: 95px;
    z-index: 10;
    text-align: left;
}

.tooltip-hr {
    width: 70px;
    background-color: #91a4a5;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -17px;
    margin-bottom: 25px;
}

.tooltip-desc {
    padding-left: 10px;
    margin-top: -20px;
    margin-left: 20px;
    overflow: auto;
}

.tooltip-desc .name {
    color: #962828;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 2px;
    text-decoration: none;
}

.tooltip-desc .name:hover {
    text-decoration: underline;
}

.tooltip-desc .position {
    color: #59525b;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 2px;
    margin-top: 0px;
}

.tooltip-desc .area {
    color: #91a4a5;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 2px;
    margin-top: 7px;
}

.tooltip-desc .office {
    color: #91a4a5;
    line-height: 160%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -10px;
    margin-top: -5px;
}

.tooltip-desc .tags-wrapper .title {
    display: inline-block;
    float: left;
}

.tooltip-desc .tags-wrapper .tags {
    display: inline-block;
    float: left;
}

.bottom-tooltip-hr {
    width: 100%;
    background-color: #58993e;
    height: 3px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -17px;
}

.btn-tooltip-department {
    margin-top: 20px;
}

.btn.disabled {
    background-color: #DFDFDF !important;
    box-shadow: none;
    color: #9F9F9F !important;
    cursor: default;
}

.btn {
    border: none;
    border-radius: 2px;
    height: 36px;
    line-height: 36px;
    outline: 0;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    transition: .2s ease-out;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.btn:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn.disabled:hover {
    box-shadow: none;
}


/* ####################################### TAGS ###################################### */

.tags {
    list-style: none;
    margin-top: -9px;
    margin-left: 5px;
    overflow: hidden;
    padding: 0;
}

.tags-wrapper {
    font-size: 2.28rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0;
}

.tags-wrapper .title {
    color: #91a4a5;
    font-size: 24px;
}

.tags li {
    float: left;
}

.tag {
    font-size: 11px;
    background: #E1ECF4;
    border-radius: 2px;
    color: #39739d;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 5px 0 5px;
    position: relative;
    margin: 0 5px 5px 0;
    text-decoration: none;
    -webkit-transition: color 0.2s;
}


/* #############################   Buttons  ############################################*/

.btn-search {
    top: 80px;
}

.btn-fullscreen {
    top: 20px;
}

.btn-back {
    top: 20px;
    left: 20px;
    display: none;
}

.btn-show-my-self {
    top: 50px;
}

.btn-action {
    position: absolute;
    right: 25px;
    height: 26px;
    color: white;
    background-color: #aa1414;
    border: 1px solid black;
    border-radius: 12px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}

.btn-action:focus {
    outline: 0;
    background-color: #aa1414;
}

.btn-action:hover {
    background-color: #490b0b;
}

.btn-action i {
    font-size: 14px;
}

.btn-action .icon {
    background-color: #c19e45;
    padding: 5px 6px 5px 6px;
    border-radius: 11px;
    margin-right: -7px;
}


/* ############################################## SVG ################################# */

.nodeHasChildren {
    fill: white;
}

.nodeDoesNotHaveChildren {
    fill: white;
}

.nodeRepresentsCurrentUser {
    stroke: Chartreuse;
    stroke-width: 3;
}

text {
    fill: dimgray;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
}

.node {
    cursor: pointer;
}

.node-collapse {
    stroke: grey;
}

.node-collapse-right-rect {
}

.node text {
    fill: white;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 22px;
    font-weight: bold;
}

.node circle {
    stroke-width: 1px;
    /*fill: #70c645;*/
    fill: lightgray;
}

.node-group .emp-name {
    fill: #962828;
    font-size: 12px;
    font-weight: 600
}

.node-group .emp-position-name {
    fill: #59525b;
    font-size: 11px;
}

.node-group .emp-area {
    fill: #91a4a5;
    font-size: 10px;
}

.node-group .emp-count,
.node-group .emp-count-icon {
    fill: #91a4a5;
    font-size: 12px;
}
