.chart-container {
    display: flex;
    position: relative;
    width: 90%;
    max-width: 1700px;
    margin: auto;
    max-height: fit-content;
    height: 84vh;
    overflow: hidden;
    border: 1px dashed #D8D8D8;
    margin-top: 12px;
    margin-bottom: 12px;
}

.chart-container > svg {
    height: 100%;
}

.chart-button-container {
    display: flex;
    width: 600px;
    margin: auto;
}

.alert-light {
    color: black;
    background-color: #F6F5F3;
    margin-top: 20px;


}

.alert-light a {
    background-color: white;
    border: 2px solid #f6f5f3;
}

.toggle-label {
    outline: none !important;
}

.export-button {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: black;
    color: white;
    height: 32px;
    width: 100px;
}
